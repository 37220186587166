import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { SignUpForm } from "@sumit-platforms/ui-bazar/v3";
import {
  useAuth,
  useMFA,
  useUserInvitation,
} from "@sumit-platforms/ui-bazar/hooks";
import { useNavigate } from "react-router-dom";
import { MfaVerify, SpinningLoader } from "@sumit-platforms/ui-bazar";
import { Box } from "@mui/material";

import "./Auth.scss";

const SignUpPage: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stage, setStage] = useState<"signupForm" | "mfa">("signupForm");
  const { loading, invitationDetails } = useUserInvitation({ config });
  const { signOut, handleUserAuthentication } = useAuth({
    config,
  });

  const handleUserEnrollSuccess = useCallback(() => {
    setStage("mfa");
  }, []);

  const handleUserEnrollFailed = useCallback(() => {
    console.error("user enroll failed");
  }, []);

  const handleUserAuthenticated = useCallback(async () => {
    await handleUserAuthentication();
    console.log("navigate");
    navigate("/");
  }, [navigate, handleUserAuthentication]);

  const { enrollSMS } = useMFA({
    config,
    onEnrollSuccess: () => {
      handleUserEnrollSuccess();
    },
    onEnrollFail: (error) => {
      handleUserEnrollFailed();
    },
  });

  const handleUserLogout = useCallback(async () => {
    try {
      await signOut({ redirectAfterLogout: false });
      setStage("signupForm");
    } catch (e) {
      console.error(e);
    }
  }, [signOut]);

  const onUserSignupSuccess = useCallback(() => {
    enrollSMS();
  }, [enrollSMS]);

  if (!invitationDetails && !loading)
    return (
      <Box
        height={"100vh"}
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {t("invitation_link_is_not_valid")}
      </Box>
    );
  return (
    <div className="SignUpPage">
      {loading ? (
        <Box height={"80px"} width={"80px"} margin={"auto"}>
          <SpinningLoader />
        </Box>
      ) : (
        <>
          {stage === "signupForm" && (
            <SignUpForm onSignupSuccess={onUserSignupSuccess} config={config} />
          )}
          {stage === "mfa" && (
            <MfaVerify
              config={config}
              type={"sms"}
              onFail={handleUserEnrollFailed}
              onSuccess={handleUserAuthenticated}
              logOut={handleUserLogout}
              showHeader
              showHeaderImage={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SignUpPage;
