import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, ThemeProvider, Typography } from "@mui/material";
import { bazarTheme, Button, FormInput } from "@sumit-platforms/ui-bazar";
import { Client } from "@sumit-platforms/types";
import { useToast } from "@sumit-platforms/ui-bazar/hooks";
import { useGlobalData } from "../../../../store/globalData";
import { useForm } from "react-hook-form";

import "./AddClientModal.scss";

export interface AddClientModalProps {
  confirm: ({
    clientName,
    clientAdminEmail,
  }: {
    clientName: string;
    clientAdminEmail?: string;
  }) => Promise<Client>;
  cancel: () => void;
}

interface AddClientInputs {
  clientName: string;
  clientAdminEmail?: string;
}

export const AddClientModal: FC<AddClientModalProps> = ({
  confirm,
  cancel,
}) => {
  const { t } = useTranslation();
  const { setToast } = useGlobalData();
  const { toastSuccess, toastError, toastInfo } = useToast({ setToast });
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AddClientInputs>({ mode: "onChange" });

  const onSubmit = async (formData: AddClientInputs) => {
    setIsLoading(true);
    try {
      toastInfo(t("creating_new_client"));
      const client = await confirm({
        clientName: formData.clientName,
        clientAdminEmail: formData.clientAdminEmail,
      });
      if (client) {
        toastSuccess(t("new_client_success"));
      }
    } catch (e) {
      console.error(e);
      toastError(t("new_client_failed"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box className={"AddClientModal"} width={"30rem"} px={4}>
        <Box display={"flex"} flexDirection={"column"} width={"100%"} pt={4}>
          <Box pb={2}>
            <Typography variant={"h3"}>{t("add_new_client")}:</Typography>
          </Box>
          <Box py={2}>
            <Typography variant={"subtitle1"}>
              {t("add_new_client_subtitle")}
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <FormInput
              label={`${t("client_name")}:`}
              error={errors["clientName"]?.message as string}
              register={{
                ...register("clientName", {
                  required: t("client_name_required"),
                }),
              }}
            />
            <FormInput
              label={`${t("admin_email")}:`}
              error={errors["clientAdminEmail"]?.message as string}
              register={{
                ...register("clientAdminEmail", {
                  required: false,
                }),
              }}
            />
          </Box>
          <Box display={"flex"} gap={2} justifyContent={"flex-end"} py={2}>
            <Button onClick={cancel} variant="outlined">
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={!isValid || isLoading}
              loading={isLoading}
              type={"submit"}
            >
              {t("create_client")}
            </Button>
          </Box>
        </form>
      </Box>
    </ThemeProvider>
  );
};
