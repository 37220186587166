import config from "../config";
import { create } from "zustand";
import {
  JobService,
  MetadataService,
  ProjectService,
} from "@sumit-platforms/ui-bazar/services";
import { LanguageData, Project, Toast } from "@sumit-platforms/types";
import { getLanguageDirection } from "@sumit-platforms/ui-bazar/utils";
import i18n from "../i18n";

const jobService = JobService({ config });
const projectService = ProjectService({ config });
const metadataService = MetadataService({ config });

export type GlobalData = {
  jobTypes: any[];
  setJobTypes: (jobTypes: any[]) => void;
  fetchGlobalData: any;
  toast: Toast | null;
  setToast: (toast: Toast | null) => void;
  projects: Project[];
  setProjects: (projects: Project[]) => void;
  direction: "ltr" | "rtl";
  setDirection: (direction: "ltr" | "rtl") => void;
  languages: LanguageData[];
  setLanguages: (languages: LanguageData[]) => void;
};

export const useGlobalData = create<GlobalData>((set) => ({
  jobTypes: [],
  setJobTypes: (jobTypes: any[]) => {
    set((state: GlobalData) => {
      return { ...state, jobTypes };
    });
  },
  fetchGlobalData: async () => {
    try {
      const jobTypes = await jobService.getJobTypes();
      const projects = await projectService.getProjects();
      const { languages } = await metadataService.getMetadata(["languages"]);
      set((state) => ({ ...state, jobTypes, projects, languages }));
    } catch (err) {
      console.error(err);
    }
  },
  toast: null,
  setToast: (toast: Toast | null) => {
    set((state: GlobalData) => {
      return { ...state, toast };
    });
  },
  direction: getLanguageDirection(i18n.language), // Initialize with i18n.getDir()
  setDirection: (direction: "ltr" | "rtl") => {
    set((state: GlobalData) => {
      return { ...state, direction };
    });
  },
  projects: [],
  setProjects: (projects: Project[]) => {
    set((state: GlobalData) => {
      return { ...state, projects };
    });
  },
  languages: [],
  setLanguages: (languages: LanguageData[]) => {
    set((state: GlobalData) => {
      return { ...state, languages };
    });
  },
}));
