import { ThemeProvider } from "@emotion/react";
import React, { FC, useEffect, useMemo, useState } from "react";
import { bazarTheme } from "../../bazar-theme";
import { Box, Theme } from "@mui/material";
import { Button } from "../Button/Button";
import { useSearchParams } from "react-router-dom";

export interface View {
  tab: string;
  view: JSX.Element;
  hidden?: boolean;
}

export type Views = Record<string, View>;

interface TabViewProps {
  views: Views;
  defaultTab?: string;
  theme?: Theme;
  allowSearchParams?: boolean;
  size?: "small" | "large";
}

export const TabView: FC<TabViewProps> = ({
  views,
  defaultTab,
  theme = bazarTheme,
  allowSearchParams = false,
  size = "large",
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [searchParams, setSearchParams] = allowSearchParams
    ? useSearchParams()
    : [];

  const tab = useMemo(() => {
    return allowSearchParams ? searchParams?.get("tab") : activeTab;
  }, [searchParams, activeTab]);

  const handleChangeView = (key: string) => {
    allowSearchParams && setSearchParams
      ? setSearchParams({ tab: key })
      : setActiveTab(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box display={"flex"} flexDirection={"column"}>
        <Box
          py={size === "small" ? 1 : 3}
          width={"100%"}
          display={"flex"}
          gap={2}
        >
          {Object.entries(views).map((entry) => {
            const [key, view] = entry;
            return (
              !view.hidden && (
                <Button
                  variant={key === tab ? "contained" : "text"}
                  onClick={() => handleChangeView(key)}
                  key={key}
                  color={key === tab ? "secondary" : "primary"}
                  sx={{
                    boxShadow: "none",
                    borderRadius: 5,
                    fontSize: size === "small" ? 14 : 19,
                    paddingY: 1.2,
                    paddingX: 2,
                    lineHeight: 1,
                  }}
                >
                  {view.tab}
                </Button>
              )
            );
          })}
        </Box>
        <Box>{tab && views[tab]?.view}</Box>
      </Box>
    </ThemeProvider>
  );
};
