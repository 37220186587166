import config from "../../config";
import React, { useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import {
  Breadcrumbs,
  NewUploads,
  SpinningLoader,
} from "@sumit-platforms/ui-bazar";
import { useFeatureFlag, useToast } from "@sumit-platforms/ui-bazar/hooks";
import useClients from "../../hooks/useClients";
import { useGlobalData } from "../../store";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import "./NewUploadFiles.scss";

const NewUploadFiles = () => {
  const { t } = useTranslation();
  const { setToast, languages, direction } = useGlobalData();
  const toast = useToast({ setToast });
  const concatMediaFF = useFeatureFlag("opera_concat_media");
  const params = useParams();
  const idClient = useMemo(() => Number(params.idClient), [params.idClient]);
  const { client, isLoading: isClientLoading } = useClients({
    idClient,
    config,
  });

  const getCrumbs = useCallback(
    () => [
      {
        label: "Client",
        path: "/clients",
      },
      {
        label: client?.name || "",
        path: `/clients/${idClient}`,
      },
      { label: t("add_job") },
    ],
    [idClient, client, t]
  );

  return (
    <Grid
      className="NewUploadFiles Page"
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} height={"100%"}>
        {isClientLoading || !client ? (
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <Grid item width={60} height={60}>
              <SpinningLoader />
            </Grid>
          </Grid>
        ) : (
          <>
            <Breadcrumbs crumbs={getCrumbs()} />
            <NewUploads
              config={config}
              client={client}
              toast={toast}
              featureFlags={{
                concatMedia: concatMediaFF,
              }}
              languages={languages}
              dir={direction}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default NewUploadFiles;
