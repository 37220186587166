import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { Button } from "@sumit-platforms/ui-bazar";
import { useAuth } from "@sumit-platforms/ui-bazar/hooks";
import { useTranslation } from "react-i18next";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import {
  AuthGooleIcon,
  AuthMSIcon,
  AuthAppleIcon,
} from "@sumit-platforms/common-assets/images";
import { useSearchParams } from "react-router-dom";

export default function PreLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { user, signIn, signOut } = useAuth({
    config,
  });

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      await signIn(searchParams.get("callbackUrl"));
    } catch (err) {
      console.error(err);
      setError(t("signin_error"));
      setIsLoading(false);
    }
  };
  const handleSignOut = async () => {
    try {
      setIsLoading(true);
      await signOut();
    } catch (err) {
      console.error(err);
      setError(t("signout_error"));
      setIsLoading(false);
    }
  };
  return (
    <div className="PreLoginPage">
      <div className="authContainer">
        <div className="headerTitle">
          <img src={sumitLogoBlack} alt="Sumit-AI" className="headerLogo" />
        </div>

        <h1 className="preloginTitle">{t("prelogin_welcome_back")}</h1>
        <p className="preloginDescription">
          {t("prelogin_login_instructions")}
        </p>
        <p className="preloginNote">{t("prelogin_files_unchanged")}</p>

        <div className="userPasswordContainer">
          {user ? (
            <Button onClick={handleSignOut} loading={isLoading}>
              {t("sign_out")}
            </Button>
          ) : (
            <Button onClick={handleSignIn} loading={isLoading}>
              {t("sign_in")}
            </Button>
          )}
        </div>
        <span className="error">{error}</span>
      </div>
    </div>
  );
}
