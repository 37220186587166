enum JobHistoryTrigger {
  TRANSCRIBE_CB = "TRANSCRIBE_CB",
  TRANSLATION_TRANSCRIBE_CB = "TRANSLATION_TRANSCRIBE_CB",
  TRANSLATE_CB = "TRANSLATE_CB",
  REMAP_CB = "REMAP_CB",
  ALIGNER_CB = "ALIGNER_CB",
  IMPORT_SOURCE_FILE_CB = "IMPORT_SOURCE_FILE_CB",
  SPLIT_CB = "SPLIT_CB",
  KIT_UPLOAD_CB = "KIT_UPLOAD_CB",
  MANUAL_STT_CB = "MANUAL_STT_CB",
  RETAKE_DONE_CB = "RETAKE_DONE_CB",
  RERUN_CB = "RERUN_CB",
  REJECT_AUTO_STT = "REJECT_AUTO_STT",
  SKIP_STT = "SKIP_STT",
  KIT_MERGE = "KIT_MERGE",
  SPLIT_JOB = "SPLIT_JOB",
  HANDLE_ALL_SPLIT_DONE = "HANDLE_ALL_SPLIT_DONE",
  ALIGNER_REQUEST = "ALIGNER_REQUEST",
  RESIGN_FROM_TASK = "RESIGN_FROM_TASK",
  DONE_JOB = "DONE_JOB",
  DONE_ASSIGNMENT = "DONE_ASSIGNMENT",
  UI = "UI",
  TRANSCRIBE_MANUAL = "TRANSCRIBE_MANUAL",
  REVIEW_MANUAL = "REVIEW_MANUAL",
  MY_JOBS_TABLE_DONE = "MY_JOBS_TABLE_DONE",
  EDITOR_DONE_BUTTON = "EDITOR_DONE_BUTTON",
  OOONA_FINISH_PROJECT = "OOONA_FINISH_PROJECT",
  REVERT_JOB_STATUS = "REVERT_JOB_STATUS",
  HANDLE_AUTO_DELIVERY = "HANDLE_AUTO_DELIVERY",
}

export { JobHistoryTrigger };
