import _ from "lodash";
import config from "../../config";
import { version } from "../../../project.json";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";

import {
  ErrorPage,
  Layout,
  Modal,
  Navigation,
  Profile,
  Toaster,
  UploadManager,
  ReAuthModal,
  Toast,
} from "@sumit-platforms/ui-bazar";

import {
  sumitLogoShort,
  sumitLogoWhite,
  userImage,
} from "@sumit-platforms/common-assets/images";
import {
  getDayPeriod,
  getLanguageDirection,
} from "@sumit-platforms/ui-bazar/utils";
import {
  useAttachFFContext,
  useErrorInterceptor,
  useFeatureFlag,
  useAuth,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";
import { uploadStore, useModal } from "@sumit-platforms/ui-bazar/store";

import { useTranslation } from "react-i18next";

import { clientStore, useNavigationBlocker, useGlobalData } from "../../store";

import { useMenuItems } from "./menuItems";

import "./Dashboard.scss";

export const Dashboard: FC = () => {
  const { t } = useTranslation();
  const languageSwitchFF = useFeatureFlag("opera_languageSwitch");
  const forceLogin401FF = useFeatureFlag("opera_401_force_login");
  const reAuth498FF = useFeatureFlag("opera_498_re_auth");
  const newUploadsFF = useFeatureFlag("opera_new_upload_files");
  const { user, signOut, signInWithEmailAndPassword, resetPassword } = useAuth({
    config,
  });
  const { client } = clientStore();
  const attachFFContext = useAttachFFContext();

  const { fetchGlobalData, toast, setToast, direction, setDirection } =
    useGlobalData();
  const { blockNavModal } = useNavigationBlocker();

  const { toastSuccess, toastError } = useToast({ setToast });

  useEffect(() => {
    fetchGlobalData();
  }, []);

  const {
    modalContent,
    popModalContent,
    clearModalContent,
    modalType,
    setModalType,
    setModalContent,
  } = useModal();

  const {
    pendingUploads,
    activeUploads,
    succeedUploads,
    progress,
    uploadInfoText,
    clearSucceedUploads,
  } = uploadStore();

  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const menuItems = useMenuItems(t, onNavigationMenuItemClick);

  const closeModal = useCallback(() => {
    setModalType("info");
    popModalContent();
  }, [popModalContent, setModalType]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);

  useEffect(() => {
    attachFFContext({
      userId: user?.email,
      properties: {
        clientId: client?.idClient?.toString() || "",
      },
    });
  }, [client, user]);

  const onReAuthSuccess = useCallback(() => {
    toastSuccess(t("reauth_success"));
    clearModalContent();
  }, [toastSuccess]);

  const onResetPasswordSuccess = useCallback(() => {
    setTimeout(() => {
      clearModalContent();
      toastSuccess(t("reset_password_success"));
    }, 10000);
  }, [toastSuccess]);

  const onReAuthFail = useCallback(() => {
    toastError(t("reauth_fail"));
    clearModalContent();
  }, [toastError]);

  const openReauthModal = useCallback(() => {
    if (modalContent?.length) return;
    setModalType("info");
    setModalContent(
      <ReAuthModal
        signInWithEmailAndPassword={signInWithEmailAndPassword}
        userEmail={user?.email}
        config={config}
        onSuccess={onReAuthSuccess}
        onFail={onReAuthFail}
        onResetPasswordSuccess={onResetPasswordSuccess}
        resetPassword={resetPassword}
      />
    );
  }, [
    user,
    onReAuthSuccess,
    onReAuthFail,
    modalContent,
    onResetPasswordSuccess,
    setModalContent,
    setModalType,
    signInWithEmailAndPassword,
    resetPassword,
  ]);

  function onNavigationMenuItemClick(to: string) {
    if (blockNavModal) {
      return blockNavModal({ to });
    }

    return navigate(to);
  }

  useErrorInterceptor({
    signOut,
    openReauthModal,
    featureFlags: {
      forceLogin401: forceLogin401FF,
      reAuth498: reAuth498FF,
    },
  });

  return (
    <div className={"Dashboard"}>
      <Layout
        isOpen={isMenuOpen}
        navigation={
          <Navigation
            languageSwitchFF={languageSwitchFF}
            isOpen={isMenuOpen}
            openStateLogo={sumitLogoWhite}
            closeStateLogo={sumitLogoShort}
            menuItems={menuItems}
            toggleIsOpen={() =>
              setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)
            }
            versionHistoryLink={config.versionHistory}
            version={version}
            direction={direction}
            onLanguageChange={(lang: string) =>
              setDirection(getLanguageDirection(lang))
            }
            profile={
              <Profile
                greeting={t("greetings_period", {
                  period: t(getDayPeriod()),
                })}
                showDetails={isMenuOpen}
                fullName={user?.firstName + " " + user?.lastName}
              />
            }
          />
        }
      >
        <ErrorBoundary
          showDialog
          fallback={({ error, resetError }) => (
            <ErrorPage
              title={t("something_went_wrong")}
              text={error.message}
              redirectToOnClick={"/"}
            ></ErrorPage>
          )}
          onReset={() => navigate("/")}
        >
          <Outlet />
        </ErrorBoundary>
      </Layout>
      {modalContent.map((modal, i: number) => (
        <div key={i + "modal"}>
          <Modal closeModal={closeModal} type={modalType}>
            {modal}
          </Modal>
        </div>
      ))}
      {!_.isEmpty([...pendingUploads, ...activeUploads, ...succeedUploads]) && (
        <UploadManager
          pendingUploads={pendingUploads}
          activeUploads={activeUploads}
          succeedUploads={succeedUploads}
          clearSucceedUploads={clearSucceedUploads}
          progress={progress}
          uploadInfoText={uploadInfoText}
          featureFlags={{
            newUploads: newUploadsFF,
          }}
        />
      )}
      <Toaster />
      {toast && (
        <div className="Toaster">
          <Toast severity={toast.severity}>{toast.text}</Toast>
        </div>
      )}
    </div>
  );
};
