import config from "../config";
import _ from "lodash";
import { Client, Media, Upload } from "@sumit-platforms/types";
import { GeneralTable } from "@sumit-platforms/ui-bazar";
import { useRef, useState } from "react";
import { useHeadCells, useUploads } from "@sumit-platforms/ui-bazar/hooks";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { t } from "i18next";
import { MediaService, JobService } from "@sumit-platforms/ui-bazar/services";
import { useToast, useAuth } from "@sumit-platforms/ui-bazar/hooks";
import { useGlobalData } from "../store";

interface MediaRow {
  id: number;
  name: string;
  isDownsampled: boolean;
  fps: number;
  isMain: boolean;
  actions: any;
  isLoading?: boolean;
}

const MediaTable = ({
  idJob,
  media,
  width,
  client,
}: {
  idJob?: number;
  media: Media[];
  width?: number;
  client: Client;
}) => {
  const mediaService = MediaService({ config });
  const jobService = JobService({ config });
  const { user } = useAuth({ config });

  const { handleUploadMediaToJob } = useUploads({
    config,
    user,
    idClient: client.idClient,
  });
  const { setToast } = useGlobalData();
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast({
    setToast,
  });

  const fileInputRef = useRef<HTMLInputElement>(
    document.createElement("input")
  );
  const [mediaRows, setMediaRows] = useState(formatMediasToRows(media));

  const getTableContext = (row: MediaRow) => {
    return [
      {
        name: t("download"),
        action: () => handleDownload(row),
      },
      {
        name: t("downsample"),
        action: () => handleDownsample(row),
      },
      {
        name: t("create_peaks"),
        action: () => handleCreatePeaks(row),
      },
      {
        name: t("set_main"),
        action: () => handleSetMainMedia(row),
        disabled: row.isMain,
      },
      {
        name: t("delete_media"),
        action: () => handleDeleteMedia(row),
        disabled: row.isMain,
        color: "red",
      },
    ];
  };

  const headCells = useHeadCells({
    headCellsKeys: [
      "mediaName",
      "mediaIsDownsampled",
      "mediaFps",
      "contextMenu",
    ],
    tableContextCallBack: getTableContext,
    labelAction: {
      contextMenu: handleAddMedia,
    },
    labelIcon: {
      contextMenu: faPlusCircle,
    },
    styles: {
      mediaName: {
        width: "60%",
      },
      mediaIsDownsampled: { width: "30%" },
      mediaFps: { width: "10%" },
      contextMenu: {
        width: "10px",
      },
    },
  });

  function formatMediasToRows(medias: Media[]): MediaRow[] {
    const rows = medias.map(
      (m) =>
        ({
          id: m.idMedia,
          name: m.name,
          isDownsampled: m.isDownsampled,
          fps: m.fps,
          isMain: !!m.isMain,
        } as MediaRow)
    );
    return _.orderBy(rows, ["isMain", "id"], ["desc", "asc"]);
  }

  const updateMediaRow = (idMedia: number, rowData: Partial<MediaRow>) => {
    const updatedJobRows = _.clone(mediaRows);
    const jobRowIndex = mediaRows.findIndex((jr) => jr.id === idMedia);
    updatedJobRows[jobRowIndex] = {
      ...updatedJobRows[jobRowIndex],
      ...rowData,
    };
    setMediaRows(updatedJobRows);
  };

  async function handleAddMedia() {
    try {
      if (!idJob) return;

      fileInputRef.current.type = "file";
      fileInputRef.current.accept = "video/*,audio/*";
      fileInputRef.current.multiple = true;
      fileInputRef.current.style.display = "none";
      fileInputRef.current.click();

      fileInputRef.current.onchange = async () => {
        if (!fileInputRef.current) return;
        if (
          !fileInputRef.current.files ||
          fileInputRef.current.files.length === 0
        ) {
          toastWarning(t("no_files_selected"));
          return;
        }

        const files = Array.from(fileInputRef.current.files);
        await handleUploadMediaToJob({
          files,
          // getToken: authService.getToken,
          onFail: () => toastError(t("upload_failed")),
          onSuccess: handleAddMediaSuccess,
          client,
          idJob,
        });
      };
    } catch (error) {
      console.error("Error while adding media:", error);
    }
  }

  const handleAddMediaSuccess = (upload: Upload) => {
    const updatedMediaRows = formatMediasToRows(upload.media);
    setMediaRows(updatedMediaRows);
  };

  const handleDownload = async (row: MediaRow) => {
    updateMediaRow(row.id, { isLoading: true });
    const url = await mediaService.downloadMediaById(row.id);
    if (url && typeof url === "string") {
      window.open(url, "_blank");
    } else {
      toastError(t("download_media_error"));
    }
    updateMediaRow(row.id, { isLoading: false });
  };

  const handleDownsample = async (row: MediaRow) => {
    try {
      updateMediaRow(row.id, { isLoading: true });
      await mediaService.downsampleMedia(row.id);
      toastSuccess(t("downsample_success"));
    } catch (error) {
      toastError(t("downsample_fail"));
    } finally {
      updateMediaRow(row.id, { isLoading: false });
    }
  };

  const handleCreatePeaks = async (row: MediaRow) => {
    try {
      if (!idJob) return;
      updateMediaRow(row.id, { isLoading: true });
      await jobService.createJobPeaks(idJob);
      toastSuccess(t("peaks_success"));
    } catch (error) {
      toastError(t("peaks_fail"));
    } finally {
      updateMediaRow(row.id, { isLoading: false });
    }
  };

  const handleSetMainMedia = async (row: MediaRow) => {
    try {
      updateMediaRow(row.id, { isLoading: true });
      const newMedias = await mediaService.setMainMedia(row.id);
      setMediaRows(formatMediasToRows(newMedias));
      toastSuccess(t("set_main_media_success"));
    } catch (error) {
      toastError(t("set_main_media_fail"));
    }
  };

  const handleDeleteMedia = async (row: MediaRow) => {
    try {
      if (!idJob) return;
      updateMediaRow(row.id, { isLoading: true });
      await mediaService.deleteMedia(row.id);
      setMediaRows(mediaRows.filter((m) => m.id !== row.id));
      toastSuccess(t("delete_media_success"));
    } catch (error) {
      toastError(t("delete_media_fail"));
      updateMediaRow(row.id, { isLoading: false });
    }
  };

  return (
    <GeneralTable
      rows={mediaRows}
      headCells={headCells.headCells}
      allowSelect={false}
      width={width}
    />
  );
};

export default MediaTable;
