import config from "../config";
import axios from "axios";
import {
  AssignedUser,
  AssignmentRequest,
  AssignmentTask,
  JobFilterOptions,
  MyAssignment,
  QueryParams,
} from "@sumit-platforms/types";

const assignmentsEndpoint = `${config.server.host}/${config.server.assignments}`;

const AssignmentService = () => {
  const getCommunityMembersToAssign = async (
    idJobs: number[],
    task: AssignmentTask
  ) => {
    try {
      const response = await axios.post<AssignedUser[]>(
        `${assignmentsEndpoint}/getCommunityMembersToAssign`,
        {
          idJobs,
          task,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const assignJobToCommunityMember = async ({
    idJobs,
    task,
    idUser,
    payPreviousCommunityMember,
    previousCommunityMember,
  }: {
    idJobs: number[];
    task: AssignmentTask;
    idUser?: number;
    payPreviousCommunityMember?: boolean;
    previousCommunityMember?: AssignedUser;
  }) => {
    try {
      const response = await axios.post(
        `${assignmentsEndpoint}/assignJobToCommunityMember`,
        {
          idJobs,
          task,
          idUser,
          payPreviousCommunityMember,
          previousCommunityMember,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAssignmentRequests = async (
    query: QueryParams<JobFilterOptions>
  ) => {
    try {
      const response = await axios.get<AssignmentRequest[]>(
        `${assignmentsEndpoint}/getAssignmentRequests`,
        {
          params: query,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const acceptCommunityMemberRequest = async (idUsersJobs: number) => {
    try {
      const response = await axios.put(
        `${assignmentsEndpoint}/acceptCommunityMemberRequest`,
        { idUsersJobs }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const rejectCommunityMemberRequest = async (idUsersJobs: number) => {
    try {
      const response = await axios.put(
        `${assignmentsEndpoint}/rejectCommunityMemberRequest`,
        { idUsersJobs }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAssignedJobsByIdUser = async () => {
    try {
      const response = await axios.get<MyAssignment[]>(
        `${assignmentsEndpoint}/getAssignedJobsByIdUser`
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  };

  const acceptAssignedJob = async ({
    idUsersJobs,
  }: {
    idUsersJobs: number;
  }) => {
    try {
      const response = await axios.put(
        `${assignmentsEndpoint}/acceptAssignedJob`,
        {
          idUsersJobs,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  };
  const rejectAssignedJob = async ({
    idUsersJobs,
  }: {
    idUsersJobs: number;
  }) => {
    try {
      const response = await axios.put(
        `${assignmentsEndpoint}/rejectAssignedJob`,
        {
          idUsersJobs,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  };

  const resignJob = async ({ idUsersJobs }: { idUsersJobs: number }) => {
    try {
      const response = await axios.put(`${assignmentsEndpoint}/resignJob`, {
        idUsersJobs,
      });
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  };

  const doneAssignment = async ({
    idJob,
    task,
    trigger,
  }: {
    idJob: number;
    task: AssignmentTask;
    trigger?: string;
  }) => {
    try {
      const response = await axios.put(
        `${assignmentsEndpoint}/doneAssignment`,
        {
          idJob,
          task,
          trigger,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  };
  const communityMemberRequestJob = async ({
    idJob,
    task,
  }: {
    idJob: number;
    task: AssignmentTask;
  }) => {
    try {
      const response = await axios.post(
        `${assignmentsEndpoint}/communityMemberRequestJob`,
        {
          idJob,
          task,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  };

  return {
    getCommunityMembersToAssign,
    assignJobToCommunityMember,
    getAssignmentRequests,
    acceptCommunityMemberRequest,
    rejectCommunityMemberRequest,
    acceptAssignedJob,
    rejectAssignedJob,
    resignJob,
    getAssignedJobsByIdUser,
    doneAssignment,
    communityMemberRequestJob,
  };
};

export default AssignmentService();
